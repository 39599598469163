import { select, all, put, takeEvery } from 'redux-saga/effects'
import { getUserSession, getLastUpdated, getLastFetch } from '../constants/stateAccessors'
import { logout } from '../awsCognitor/awsActions'
import * as actionTypes from '../actions/actionTypes'
import {
  updateRequiredListsSuccess,
  getIndividualNamazReportsSuccess,
  getMajlisNamazReportsSuccess,
  getActivityReportsSuccess,
  deleteActivityReportSuccess,
  getOwnNamazReportsSuccess,
  deleteNamazReportSuccess,
  getOwnMuhtamimReportsSuccess,
  deleteMuhtamimReportSuccess,
  getOwnDoorToDoorReportsSuccess,
  deleteDoorToDoorReportSuccess,
  getDoorToDoorSummarySuccess,
  getAllMuhtamimReportsSuccess,
  getAllActivityReportsSuccess,
  ijtemaGetSummarySuccess,
  ijtemaGetMembersSuccess,
  ijtemaMarkAsPresentSuccess,
  getSpiritualReformReportsSuccess,
  ijtemaGetMembersSuccess2023,
  ijtemaGetSummarySuccess2023,
} from '../actions'
import { message, notification } from 'antd'
import dayjs from 'dayjs'

export function* processUpdateRequiredLists() {
  let lastFetch = yield select(getLastFetch)
  if (lastFetch === undefined || dayjs(lastFetch).add(30, 'minutes').isBefore(dayjs())) {
    const lastUpdated = yield select(getLastUpdated)
    const userSession = yield select(getUserSession)
    let info = message.loading('Refreshing lists', 0)
    yield executeAuthenticatedFetch(
      userSession,
      JSON.stringify({
        cmd: 'getUpdatedRequiredLists',
        lastUpdated,
      }),
      function* (json) {
        lastFetch = dayjs().format()
        yield put(updateRequiredListsSuccess({ ...json, lastFetch: lastFetch }))
      },
      () => {
        notification['warning']({
          message: 'Update Failed',
          description: 'I have failed to retrieve required lists (majalis, offices, etc)',
          duration: 5,
        })
      }
    )
    info()
  }
}

export function* processGetIndividualNamazReports(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Fetching individual namaz reports', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: 'getIndividualNamazReports',
      reporter,
      ...data.payload,
    }),
    function* (json) {
      yield put(
        getIndividualNamazReportsSuccess({
          monthYear: data.payload.monthYear,
          result: json,
        })
      )
    },
    () => {
      notification['warning']({
        message: 'Failed to fetch reports',
        description: 'I have failed to retrieve reports.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processGetMajlisNamazReports(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Fetching Majlis Namaz reports', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: 'getMajlisNamazReports',
      reporter,
      ...data.payload,
    }),
    function* (json) {
      yield put(
        getMajlisNamazReportsSuccess({
          monthYear: data.payload.monthYear,
          result: json,
        })
      )
    },
    () => {
      notification['warning']({
        message: 'Update Failed',
        description: 'I have failed to retrieve recent reports.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processGetActivityReports(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Fetching activity reports', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: 'getActivityReports',
      reporter,
      ...data.payload,
    }),
    function* (json) {
      yield put(
        getActivityReportsSuccess({
          monthYear: data.payload.monthYear,
          result: json,
        })
      )
    },
    () => {
      notification['warning']({
        message: 'Failed to fetch reports',
        description: 'I have failed to retrieve reports.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processGetAllActivityReports(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Fetching all activity reports', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: 'getAllActivityReports',
      reporter,
      ...data.payload,
    }),
    function* (json) {
      yield put(
        getAllActivityReportsSuccess({
          monthYear: data.payload.monthYear,
          result: json,
        })
      )
    },
    () => {
      notification['warning']({
        message: 'Failed to fetch reports',
        description: 'I have failed to retrieve reports.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processDeleteActivityReport(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Deleting...', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: 'deleteActivityReport',
      reporter,
      id: data.payload.id,
    }),
    function* (json) {
      if (json.deleted) yield put(deleteActivityReportSuccess({ ...data.payload }))
      else
        notification['warning']({
          message: 'Failed to delete report',
          description: 'You are not allowed to perform this action.',
          duration: 5,
        })
    },
    () => {
      notification['warning']({
        message: 'Failed to delete report',
        description: 'Did not get a positive response from server.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processGetNamazReports(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Fetching namaz reports', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: 'getOwnNamazReports',
      reporter,
      ...data.payload,
    }),
    function* (json) {
      yield put(
        getOwnNamazReportsSuccess({
          monthYear: data.payload.monthYear,
          result: json,
        })
      )
    },
    () => {
      notification['warning']({
        message: 'Failed to fetch reports',
        description: 'I have failed to retrieve reports.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processDeleteNamazReport(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Deleting...', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd:
        data.payload.source === 'individualNamazReports'
          ? 'deleteIndividualNamazReport'
          : data.payload.source === 'localAmlaNamazReports'
          ? 'deleteLocalAmlaNamazReport'
          : 'deleteMajlisNamazReport',
      reporter,
      id: data.payload.id,
    }),
    function* (_) {
      yield put(deleteNamazReportSuccess({ ...data.payload }))
    },
    () => {
      notification['warning']({
        message: 'Failed to delete report',
        description: 'Did not get a positive response from server.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processGetOwnMuhtamimReports(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Fetching muhtamim reports', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: 'getOwnMuhtamimReports',
      reporter,
      ...data.payload,
    }),
    function* (json) {
      yield put(
        getOwnMuhtamimReportsSuccess({
          monthYear: data.payload.monthYear,
          result: json,
        })
      )
    },
    () => {
      notification['warning']({
        message: 'Failed to fetch reports',
        description: 'I have failed to retrieve reports.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processGetAllMuhtamimReports(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Fetching muhtamim reports', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    data.payload.viewForYear
      ? JSON.stringify({
          cmd: 'getAllMuhtamimReportsForYear',
          reporter,
          year: data.payload.date,
        })
      : JSON.stringify({
          cmd: 'getAllMuhtamimReports',
          reporter,
          monthYear: data.payload.date,
        }),
    function* (json) {
      yield put(getAllMuhtamimReportsSuccess({ key: data.payload.date, result: json }))
    },
    () => {
      notification['warning']({
        message: 'Failed to fetch reports',
        description: 'I have failed to retrieve reports.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processDeleteMuhtamimReport(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Deleting...', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: 'deleteMuhtamimReport',
      reporter,
      id: data.payload.id,
    }),
    function* (_) {
      yield put(deleteMuhtamimReportSuccess({ ...data.payload }))
    },
    () => {
      notification['warning']({
        message: 'Failed to delete report',
        description: 'Did not get a positive response from server.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processGetOwnDoorToDoorReports(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Fetching door to door reports', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: 'getOwnDoorToDoorReports',
      reporter,
      ...data.payload,
    }),
    function* (json) {
      yield put(
        getOwnDoorToDoorReportsSuccess({
          monthYear: data.payload.monthYear,
          result: json,
        })
      )
    },
    () => {
      notification['warning']({
        message: 'Failed to fetch reports',
        description: 'I have failed to retrieve reports.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processDeleteDoorToDoorReport(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Deleting...', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: 'deleteDoorToDoorReport',
      reporter,
      id: data.payload.id,
    }),
    function* (_) {
      yield put(deleteDoorToDoorReportSuccess({ ...data.payload }))
    },
    () => {
      notification['warning']({
        message: 'Failed to delete report',
        description: 'Did not get a positive response from server.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processGetDoorToDoorSummary(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Fetching door to door summary', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: 'getDoorToDoorSummary',
      reporter,
      ...data.payload,
    }),
    function* (json) {
      yield put(
        getDoorToDoorSummarySuccess({
          majlis: data.payload.majlis,
          result: json,
        })
      )
    },
    () => {
      notification['warning']({
        message: 'Failed to fetch reports',
        description: 'I have failed to retrieve reports.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processGetDoorToDoorReports(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Fetching door to door reports', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: 'getOwnDoorToDoorReports',
      reporter,
      ...data.payload,
    }),
    function* (json) {
      yield put(
        getOwnDoorToDoorReportsSuccess({
          monthYear: data.payload.monthYear,
          result: json,
        })
      )
    },
    () => {
      notification['warning']({
        message: 'Failed to fetch reports',
        description: 'I have failed to retrieve reports.',
        duration: 5,
      })
    }
  )
  info()
}

export function* processGetSpiritualReformReports(data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Fetching spiritual reform reports', 0)
  let reporter = userSession?.username

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: 'getSpiritualReformReportsForYear',
      reporter,
      ...data.payload,
    }),
    function* (json) {
      yield put(
        getSpiritualReformReportsSuccess({
          year: data.payload.year,
          result: json,
        })
      )
    },
    () => {
      notification['warning']({
        message: 'Failed to fetch reports',
        description: 'I have failed to retrieve reports.',
        duration: 5,
      })
    }
  )
  info()
}

export function* sendDirectReport(cmd, displayName, data) {
  let userSession = yield select(getUserSession)
  let info = message.loading('Sending report...', 0)
  let clear = data.payload.clear
  let onError = data.payload.onError
  delete data.payload.clear
  delete data.payload.onError

  yield executeAuthenticatedFetch(
    userSession,
    JSON.stringify({
      cmd: cmd,
      ...data.payload,
    }),
    (json) => {
      if (json.result === 'success' || json.success) {
        notification['success']({
          message: displayName,
          description: 'Report sent.',
          duration: 5,
        })
        clear()
      } else {
        notification['warning']({
          message: displayName,
          description: 'I have failed to send the report. Please try again. If problem persists, contact an admin.',
          duration: 5,
        })
        onError()
      }
    },
    () => {
      notification['warning']({
        message: displayName,
        description: 'I have failed to send the report. Please try again. If problem persists, contact an admin.',
        duration: 5,
      })
      onError()
    }
  )
  info()
}

export function* processSignupSuccess(data) {
  let userSession = yield select(getUserSession)
  if (userSession.backendUrl === '') {
    message.warning('Session Expired')
    yield put(logout())
  } else {
    const backendUrl = new URL(userSession.backendUrl)
    let payload = {
      username: data.payload.username,
      password: data.payload.password,
    }

    for (let i in data.payload.attributes) {
      payload[i.replace('custom:', '')] = data.payload.attributes[i]
    }
    try {
      yield fetch(backendUrl, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          cmd: 'addUserDetails',
          ...payload,
        }),
        headers: {
          Accept: 'application/json',
        },
      })
    } catch (e) {
      console.warn(e)
    }
  }
}

export function* processUpdateUserSuccess(data) {
  let userSession = yield select(getUserSession)
  if (userSession.backendUrl === '') {
    message.warning('Session Expired')
    yield put(logout())
  } else {
    const backendUrl = new URL(userSession.backendUrl)
    let payload = {
      username: data.payload.username,
      password: data.payload.password,
    }

    for (let i in data.payload.attributes) {
      payload[i.replace('custom:', '')] = data.payload.attributes[i]
    }
    try {
      yield fetch(backendUrl, {
        method: 'POST',
        mode: 'cors',
        body: JSON.stringify({
          cmd: 'updateUserDetails',
          reporter: userSession?.username,
          ...payload,
        }),
        headers: {
          Accept: 'application/json',
        },
      })
    } catch (e) {
      console.warn(e)
    }
  }
}

export function* processSendActivityReportSaga() {
  yield takeEvery(actionTypes.SEND_ACTIVITY_REPORT, sendDirectReport, 'sendActivityReport', 'Activity Report')
}

export function* processSendMuhtamimReportSaga() {
  yield takeEvery(actionTypes.SEND_MUHTAMIM_REPORT, sendDirectReport, 'sendMuhtamimReport', 'Muhtamim Report')
}

export function* processSendIndividualNamazReportSaga() {
  yield takeEvery(actionTypes.SEND_INDIVIDUAL_NAMAZ_REPORT, sendDirectReport, 'sendIndividualNamazReport', 'Individual Namaz Report')
}

export function* processSendLocalAmlaNamazReportSaga() {
  yield takeEvery(actionTypes.SEND_LOCAL_AMLA_NAMAZ_REPORT, sendDirectReport, 'sendLocalAmlaNamazReport', 'Local Amla Namaz Report')
}

export function* processSendMajlisNamazReportSaga() {
  yield takeEvery(actionTypes.SEND_MAJLIS_NAMAZ_REPORT, sendDirectReport, 'sendMajlisNamazReport', 'Majlis Namaz Report')
}

export function* processSendDoorToDoorReportSaga() {
  yield takeEvery(actionTypes.SEND_DOOR_TO_DOOR_REPORT, sendDirectReport, 'sendDoorToDoorReport', 'Door To Door Report')
}

export function* processGetIndividualNamazReportsSaga() {
  yield takeEvery(actionTypes.GET_INDIVIDUAL_NAMAZ_REPORTS, processGetIndividualNamazReports)
}

export function* processGetMajlisNamazReportsSaga() {
  yield takeEvery(actionTypes.GET_MAJLIS_NAMAZ_REPORTS, processGetMajlisNamazReports)
}

export function* processGetActivityReportsSaga() {
  yield takeEvery(actionTypes.GET_ACTIVITY_REPORTS, processGetActivityReports)
}

export function* processDeleteActivityReportSaga() {
  yield takeEvery(actionTypes.DELETE_ACTIVITY_REPORT, processDeleteActivityReport)
}

export function* processGetNamazReportsSaga() {
  yield takeEvery(actionTypes.GET_OWN_NAMAZ_REPORTS, processGetNamazReports)
}

export function* processDeleteNamazReportSaga() {
  yield takeEvery(actionTypes.DELETE_NAMAZ_REPORT, processDeleteNamazReport)
}

export function* processGetOwnMuhtamimReportsSaga() {
  yield takeEvery(actionTypes.GET_OWN_MUHTAMIM_REPORTS, processGetOwnMuhtamimReports)
}

export function* processDeleteMuhtamimReportSaga() {
  yield takeEvery(actionTypes.DELETE_MUHTAMIM_REPORT, processDeleteMuhtamimReport)
}

export function* processGetOwnDoorToDoorReportsSaga() {
  yield takeEvery(actionTypes.GET_OWN_DOOR_TO_DOOR_REPORTS, processGetOwnDoorToDoorReports)
}

export function* processDeleteDoorToDoorReportSaga() {
  yield takeEvery(actionTypes.DELETE_DOOR_TO_DOOR_REPORT, processDeleteDoorToDoorReport)
}

export function* processGetDoorToDoorReportsSaga() {
  yield takeEvery(actionTypes.GET_DOOR_TO_DOOR_REPORTS, processGetDoorToDoorReports)
}

export function* processGetDoorToDoorSummarySaga() {
  yield takeEvery(actionTypes.GET_DOOR_TO_DOOR_SUMMARY, processGetDoorToDoorSummary)
}

export function* processGetAllMuhtamimReportsSaga() {
  yield takeEvery(actionTypes.GET_ALL_MUHTAMIM_REPORTS, processGetAllMuhtamimReports)
}

export function* processGetAllActivityReportsSaga() {
  yield takeEvery(actionTypes.GET_ALL_ACTIVITY_REPORTS, processGetAllActivityReports)
}

export function* processUpdateRequiredListsSaga() {
  yield takeEvery('LOGGED_IN', processUpdateRequiredLists)
}

export function* processSignupSuccessSaga() {
  yield takeEvery('SIGNUP_SUCCESS', processSignupSuccess)
}

export function* processUpdateUserSuccessSaga() {
  yield takeEvery('UPDATE_USER_SUCCESS', processUpdateUserSuccess)
}

export function* processIjtemaGetSummarySaga() {
  yield takeEvery('IJTEMA_GET_SUMMARY', function* processIjtemaGetSummary() {
    const userSession = yield select(getUserSession)
    let info = message.loading('Fetching ijtema attendance summary', 0)
    yield executeAuthenticatedFetchForIjtema(
      userSession,
      JSON.stringify({
        cmd: 'getSummary',
        reporter: {
          name: 'reports',
          token: '83e8eba9-04f8-4b23-8ccc-3d49323c0000',
        },
      }),
      function* (json) {
        yield put(ijtemaGetSummarySuccess({ ...json }))
      },
      () => {
        notification['warning']({
          message: 'Update Failed',
          description: 'I have failed to retrieve latest attendance summary',
          duration: 5,
        })
      }
    )
    info()
  })
}

export function* processIjtemaGetMembersSaga() {
  yield takeEvery('IJTEMA_GET_MEMBERS', function* processIjtemaGetMembers(data) {
    const userSession = yield select(getUserSession)
    let info = message.loading('Fetching ijtema individual attendance', 0)
    yield executeAuthenticatedFetchForIjtema(
      userSession,
      JSON.stringify({
        cmd: 'getMembers',
        majlis: data.payload.majlis,
        reporter: {
          name: 'reports',
          token: '83e8eba9-04f8-4b23-8ccc-3d49323c0000',
        },
      }),
      function* (json) {
        yield put(ijtemaGetMembersSuccess({ ...json }))
      },
      () => {
        notification['warning']({
          message: 'Update Failed',
          description: 'I have failed to retrieve latest individual breakdown',
          duration: 5,
        })
      }
    )
    info()
  })
}

export function* processIjtemaGetSummary2023Saga() {
  yield takeEvery('IJTEMA_GET_SUMMARY_2023', function* processIjtemaGetSummary2023() {
    const userSession = yield select(getUserSession)
    let info = message.loading('Fetching ijtema attendance summary', 0)
    yield executeAuthenticatedFetchForIjtema(
      userSession,
      JSON.stringify({
        cmd: 'getSummary2023',
      }),
      function* (json) {
        yield put(ijtemaGetSummarySuccess2023({ ...json }))
      },
      () => {
        notification['warning']({
          message: 'Update Failed',
          description: 'I have failed to retrieve latest attendance summary',
          duration: 5,
        })
      }
    )
    info()
  })
}

export function* processIjtemaGetMembers2023Saga() {
  yield takeEvery('IJTEMA_GET_MEMBERS_2023', function* processIjtemaGetMembers2023(data) {
    const userSession = yield select(getUserSession)
    let info = message.loading('Fetching ijtema individual attendance', 0)
    yield executeAuthenticatedFetchForIjtema(
      userSession,
      JSON.stringify({
        cmd: 'getAllMembers2023',
        majlis: data.payload.majlis,
      }),
      function* (json) {
        yield put(ijtemaGetMembersSuccess2023({ ...json }))
      },
      () => {
        notification['warning']({
          message: 'Update Failed',
          description: 'I have failed to retrieve latest individual breakdown',
          duration: 5,
        })
      }
    )
    info()
  })
}

export function* processMarkAsPresentSaga() {
  yield takeEvery('IJTEMA_MARK_AS_PRESENT', function* processIjtemaGetMembers(data) {
    const userSession = yield select(getUserSession)
    let info = message.loading(`Marking ${data.payload.ijtema_id} as present on ${data.payload.day}`, 0)
    yield executeAuthenticatedFetchForIjtema(
      userSession,
      JSON.stringify({
        cmd: 'markAsPresent',
        ijtema_id: data.payload.ijtema_id,
        day: data.payload.day,
        reporter: {
          name: 'Report App',
          token: '83e8eba9-04f8-4b23-8ccc-3d49323c0c11',
        },
      }),
      function* (json) {
        yield put(ijtemaMarkAsPresentSuccess(data.payload))
        notification['success']({
          message: 'Updated!',
          duration: 5,
        })
      },
      () => {
        notification['warning']({
          message: 'Update failed',
          description: 'I have failed to mark the individual as present',
          duration: 5,
        })
      }
    )
    info()
  })
}

export function* processSendSpiritualReformReportSaga() {
  yield takeEvery(actionTypes.SEND_SPIRITUAL_REFORM_REPORT, sendDirectReport, 'sendSpiritualReformReport', 'Spiritual Reform Report')
}
export function* processGetSpiritualReformReportsSaga() {
  yield takeEvery(actionTypes.GET_SPIRITUAL_REFORM_REPORTS, processGetSpiritualReformReports)
}
export default function* backendSaga() {
  yield all([
    processUpdateRequiredListsSaga(),
    processSignupSuccessSaga(),
    processSendActivityReportSaga(),
    processSendMuhtamimReportSaga(),
    processSendIndividualNamazReportSaga(),
    processSendLocalAmlaNamazReportSaga(),
    processSendMajlisNamazReportSaga(),
    processSendDoorToDoorReportSaga(),
    processGetIndividualNamazReportsSaga(),
    processGetMajlisNamazReportsSaga(),
    processGetActivityReportsSaga(),
    processGetNamazReportsSaga(),
    processDeleteActivityReportSaga(),
    processDeleteNamazReportSaga(),
    processGetOwnMuhtamimReportsSaga(),
    processDeleteMuhtamimReportSaga(),
    processGetOwnDoorToDoorReportsSaga(),
    processDeleteDoorToDoorReportSaga(),
    processGetDoorToDoorReportsSaga(),
    processGetDoorToDoorSummarySaga(),
    processGetAllMuhtamimReportsSaga(),
    processGetAllActivityReportsSaga(),
    processUpdateUserSuccessSaga(),

    processIjtemaGetSummarySaga(),
    processIjtemaGetMembersSaga(),
    processMarkAsPresentSaga(),

    processIjtemaGetSummary2023Saga(),
    processIjtemaGetMembers2023Saga(),

    processSendSpiritualReformReportSaga(),
    processGetSpiritualReformReportsSaga(),
  ])
}

function* executeAuthenticatedFetchForIjtema(userSession, bodyJSONString, successHandler, errorHandler) {
  if (userSession.backendUrl === '') {
    message.warning('Session Expired')
    yield put(logout())
  } else {
    const backendUrl = new URL('https://script.google.com/macros/s/AKfycbxiJgx2J9DH5NyX8W59A5ZvDyX9urZnSzsN7GQGA9mXBc359PvQTjY4Elz6ED2ue1Pm/exec')
    try {
      let response = yield fetch(backendUrl, {
        method: 'POST',
        mode: 'cors',
        body: bodyJSONString,
        headers: {
          Accept: 'application/json',
        },
      })

      if (response.status === 200 || response.status === 201) {
        let json = yield response.json()
        if (json.success) {
          yield successHandler(json)
        } else {
          errorHandler()
        }
      } else {
        errorHandler()
      }
    } catch (e) {
      console.warn(e)
      errorHandler()
    }
  }
}

function* executeAuthenticatedFetch(userSession, bodyJSONString, successHandler, errorHandler) {
  if (userSession.backendUrl === '') {
    message.warning('Session Expired')
    yield put(logout())
  } else {
    const backendUrl = new URL(userSession.backendUrl)
    try {
      let response = yield fetch(backendUrl, {
        method: 'POST',
        mode: 'cors',
        body: bodyJSONString,
        headers: {
          Accept: 'application/json',
        },
      })

      if (response.status === 200 || response.status === 201) {
        let json = yield response.json()
        yield successHandler(json)
      } else {
        errorHandler()
      }
    } catch (e) {
      console.warn(e)
      errorHandler()
    }
  }
}
